import {
    Box,
    Button,
    Center,
    Container,
    Stack,
    Text,
    VStack,
    Image,
  } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import React from "react";
  
export const About = ({
    title,
    description,
    image,
    reverse,
  }) => {
    const rowDirection = reverse ? "row-reverse" : "row";
    return (
      <Center w="full" minH={[null, "90vh"]}>
        <Container maxW="container.xl" rounded="lg">
          <Stack
            spacing={[4, 16]}
            alignItems="center"
            direction={["column", null, rowDirection]}
            w="full"
            h="full"
          >
            <Box rounded="lg" align="center">
              <Image
                src={image}
                // width={684}
                // height={433}
                maxW={'700px'}
                w={{ base: "80%", sm: "90%", md: "80%" }}
                alt={`Feature: ${title}`}
                rounded={"lg"}
              />
            </Box>
  
            <VStack maxW={500} spacing={4} align={"center"}>
              {/* <Box> */}
                <Text fontSize="3xl" fontWeight={600} textAlign={"center"}>
                  {title}
                </Text>
              {/* </Box> */}
  
              <Text fontSize="md" color="gray.500" textAlign={"center"}>
                {description}
              </Text>
              {/* <Button
                colorScheme="brand"
                variant="link"
                textAlign={["center", "left"]}
              >
                Learn more →
              </Button> */}
            </VStack>
          </Stack>
        </Container>
      </Center>
    );
  };

export default About;