import { Flex, Text, Link } from '@chakra-ui/react';
import React from 'react';

const Footer = () => {
  return (
    <Flex
      w="100vw"
      bg="blackAlpha.50"
      minHeight="20vh"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
      <Text mb="3">
        {/* <Link href="/privacy" isExternal color="black.500">
          Privacy Policy
        </Link> */}
      </Text>
      <Text opacity="0.5">© 2023 FlowTime Technologies Inc.</Text>
    </Flex>
  );
};

export default Footer;