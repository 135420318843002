import React from "react";

import Hero from "../components/sections/Hero";
import Footer from "../components/sections/Footer";
import LandingLayout from "../components/layouts/LandingLayout";
import SignUpWaitList from "../components/sections/SignUpWaitList";
import About from "../components/sections/About";
import bookSession from '../assets/bookSession.png';
import focusSession from '../assets/focusSession.png';
import chatSession from '../assets/chatSessionborder.png';

const features = [
  {
    title: "1. Book a focus session",
    description:
      "FlowTime matches you with someone in your Slack workspace whenever you want to focus.",
    image: bookSession
  },
  {
    title: "2. Start a huddle",
    description:
      "Introduce yourself, share your goal for the session, and get to work!",
    image: chatSession
  },
  {
    title: "3. Celebrate your progress",
    description:
      "When your session ends, check in with your partner and celebrate your progress!",
    image: focusSession
  },
];

export default function Landing() {
  return (
    <LandingLayout>
      <Hero
        title="Experience the magic of deep work with people in your Slack workspace."
        subtitle="Connect serendipitously for 50 minute coworking sessions and crush your todo list."
        image={focusSession}
        ctaText="Request a Demo"
        ctaLink="/#signup"
      />
      <a name="how-it-works">
        {features.map(
          ({ title, description, image }, i) => {
            return (
              <About
                key={`feature_${i}`}
                title={title}
                description={description}
                image={image}
                reverse={i % 2 === 1}
              />
            );
          }
        )}
      </a>

      <a name="signup">
        <SignUpWaitList />
      </a>
      <Footer />
    </LandingLayout>
  );
}
