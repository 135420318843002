// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React, { useState } from 'react';
import { useForm } from '@formspree/react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  FormHelperText,
  Text,
  Heading
} from '@chakra-ui/react';

export default function EmailForm() {
  const [state, handleSubmit] = useForm("xbjegbwk");

  const [input, setInput] = useState('')

  const handleInputChange = (e) => setInput(e.target.value)

  const isError = input === ''

  if (state.succeeded) {
    return <Text fontSize={'2xl'}>Thanks for signing up, we'll be getting back to you shortly!</Text>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Heading
        as="h1"
        size="xl"
        fontWeight="bold"
        mb={4}
      >
        Sign Up for a Demo!
      </Heading>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input type='email' id='email' name='email' />
        {/* <FormHelperText>
          We'll only use your email to let you know the app is ready to install.
        </FormHelperText> */}
      </FormControl>
      <Button type="submit" mt={4}>
        Sign Up
      </Button>
    </form>
  )
}
